// Details inside of template/event
import React, { lazy, Suspense, useRef } from 'react'
import { handleVenueLink } from "components/utils/routeUtils"
import { useIsVisible } from 'react-is-visible'
import { pageStore } from "state/store-zustand";
import useContextTheme from 'components/utils/useContextTheme'

const Map = lazy(() => import('components/map/basicMap'))
import DateToCalendar from 'components/shared/dateToCalendar'


const TimeLocation = (props) => {
  const ref = useRef();
  const mapRef = useRef()
  const isVisible = useIsVisible(ref)

  const { eventDetails } = props
  const {
    id,
    properties,
    title
  } = eventDetails || {}

  const isDesktop = pageStore((state) => state.isDesktop)
  const isTablet = pageStore((state) => state.isTablet)
  const isEmbedded = pageStore((state) => state.embedded)

  const showDisclaimer = !isEmbedded

  const {
    address,
    description,
    hotspots_place,
    is_free,
    location,
    recurrence,
    recurrence_display,
    start_date,
    end_date,
    likes,
    organizer,
    price,
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  const height = isDesktop || isTablet
    ? 300
    : 240

  const width = isDesktop || isTablet
    ? 420
    : '100%'


  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const venue = hotspots_place?.properties

  const venueLink = handleVenueLink({
    place: venue,
    theme: themeClass,
    shouldOpenURL: theme?.cards?.open_to_website
  })
  const nameVenue = venue?.name


  const timezone = hotspots_place?.properties?.timezone || 'America/Los_Angeles'

  const latitude = hotspots_place?.geometry?.coordinates[1]
  const longitude = hotspots_place?.geometry?.coordinates[0]

  let map = hotspots_place?.geometry
    ? <div className='map'>
      <Suspense fallback={<span>...</span>}>
        <Map
          mapRef={mapRef}
          latitude={latitude}
          longitude={longitude}
          height={height}
          width={width}
          dragPan={false}
          showFullScreen={false}
          showExplore={false}
          showPlaces={false}
          showDraggableMarker={false}
          showFeaturedMarker={true}
          map3d={false}
          scrollZoom={false}
          zoom={14}
        />
      </Suspense>
    </div>
    : null

  const priceIsFree = price && typeof price == "string" && price.toUpperCase() === 'FREE'
  const isFree = is_free || priceIsFree

  const ticketPrice = isFree
    ? 'Free'
    : price
      ? '$' + price
      : null

  const ticketButton = url
    ? (
      <div className='ticket'>
        <a href={url} className='ui button fluid black large'>
          More Info
        </a>
      </div>
    )
    : null

  const organizerText = organizer ? 'Presented by: ' + organizer : null

  const is_recurring = recurrence_display !== null && recurrence_display !== undefined && recurrence !== null && recurrence !== undefined;
  const recurrence_rule = recurrence_display
    ? <p style={{ textTransform: "capitalize" }}>
      {recurrence_display}
    </p>
    : null

  return (
    <div ref={ref} className={'info location-details'}>
      {isVisible &&
        <div className="map-container">
          {recurrence_rule}
          {start_date &&
            <DateToCalendar
            address={address}
            start_date={start_date}
            end_date={end_date}
            name={title}
            showEndDate={!is_recurring}
            description={description}
            timezone={timezone} />
          }
          {likes &&
            <p>{`${likes} people like this`}</p>
          }
          {ticketPrice
            ? <p>
                <strong className='price'><span>{ticketPrice}</span></strong>
                <span className='small'>Tickets starting price</span>
              </p>
            : null
          }
          <div>
            <p>{organizerText}</p>
            <a href={venueLink}>{nameVenue}</a>
            <p>{address ? address : hotspots_place?.properties?.address}</p>
          </div>
          {map}
        </div>
      }
      {ticketButton}
      {showDisclaimer
        ?  <p className='small'>By buying your ticket here, you support Vibemap, an authorized ticket re-seller. Note that the listing price is often discounted and maybe different from the final sale price.</p>
        : null
      }
    </div>
  )
}

export default TimeLocation
